<template>
 <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      flat
    >
      <v-container fluid class="py-0 fill-height">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            3D модель объекта газопотребления
          </v-list-item-title>
        </v-list-item-content>
        <v-btn href="https://umcmrg.ru">Сайт НОЦ</v-btn>
      </v-list-item>

      <v-divider></v-divider>

      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            <v-sheet rounded fixed>

              <v-list shaped>
                <v-subheader>3D модели</v-subheader>
                <v-list-item-group
                  v-model="selectedItem"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    :to="item.to"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content :to="item.to">
                      <v-list-item-title  v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list shaped>
                <v-subheader>Обозначения</v-subheader>
                <v-list-item-group
                  color="primary"
                >
                  <v-list-item
                    to="/desc"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-camera-burst</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Схема обозначений (ОГ)</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <div v-if="$route.meta.desc === true">
              <v-list>
                <v-list-group
                  v-for="item in items2"
                  :key="item.title"
                  v-model="item.active"
                  :prepend-icon="item.action"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-for="child in item.items"
                    :key="child.title"
                    :to="child.to"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
              </div>

            </v-sheet>
          </v-col>
          <v-col class="view-content" cols="9">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
          <v-footer class="view-footer" >
      <v-col
        class="text-center"
        cols="12"
      >
        © Научно-образовательный центр {{ new Date().getFullYear() }}г.
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>
// import { ModelGltf } from 'vue-3d-model'
// import { ModelObj } from 'vue-3d-model'

export default {
  name: 'App',

  components: {
    // ModelGltf
    // ModelObj
  },

  data: () => ({
    descsription: false,
    items: [
      { title: 'Объект газопотребления (ОГ)', icon: 'mdi-rotate-3d', to: '/' },
      { title: 'Блок интерфейса и питания (БИП)', icon: 'mdi-rotate-3d', to: 'korr' },
      { title: 'Расходомер-счетчик ИРВИС-РС4М', icon: 'mdi-rotate-3d', to: 'count' },
      { title: 'Свинцовая пломба', icon: 'mdi-rotate-3d', to: 'plomb-s' },
      { title: 'Роторная индикационная пломба', icon: 'mdi-rotate-3d', to: 'plomb-r' }
    ],
    items2: [
      {
        action: 'mdi-hockey-puck',
        items: [
          { title: '1. Роторная индикационная пломба', to: '/rplomb' },
          { title: '2. Пломба контрольная (индикаторная)', to: '/kplomb' },
          { title: '3. Свинцовая пломба поверителя', to: '/splomb' }
        ],
        title: 'Пломбы'
      },
      {
        action: 'mdi-trackpad',
        items: [
          { title: '4. ГРПШ', to: '/grph' },
          { title: '5. Продувочная газовая свеча', to: '/switch' },
          { title: '6. Обогреватель ГРПШ', to: '/heater' },
          { title: '7. Манометр показывающий', to: '/manometr' },
          { title: '8. Фильтр газа сетчатый', to: '/filter' },
          { title: '9. Регулятор давления газа', to: '/rd' }
        ],
        title: 'ГРПШ'
      },
      {
        action: 'mdi-laser-pointer',
        items: [
          { title: '10. Газовый шаровой кран', to: '/crane' },
          { title: '11. Кран шаровый латунный муфтовый', to: '/cranelat' },
          { title: '12. Конфузор', to: '/confuser' },
          { title: '13. Диффузор', to: '/diffuser' },
          { title: '14. Газопровод подземный', to: '/undergas' },
          { title: '15. Незаконная врезка', to: '/illegaltie' },
          { title: '16. Газовая котельная', to: '/boiler' }
        ],
        title: 'Газопровод'
      },
      {
        action: 'mdi-developer-board',
        items: [
          { title: '17. Расходомер-счетчик ИРВИС-РС4М', to: '/irvis' },
          { title: '18. Блок интерфейса и питания (БИП)', to: '/bip' }
        ],
        title: 'УИРГ'
      }
    ],
    methods: {
      desc: function () {
        this.descsription = true
      }
    }
  })
}
</script>
<style>
.view-content {
  position: fixed;
  top: 6.5%;
  left: 25%;
}
.view-footer {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
