import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Pipe',
    component: () => import(/* webpackChunkName: "pipe" */ '../views/Pipe.vue')
  },
  {
    path: '/korr',
    name: 'Korr',
    component: () => import(/* webpackChunkName: "korr" */ '../views/Korr.vue')
  },
  {
    path: '/count',
    name: 'Korr',
    component: () => import(/* webpackChunkName: "count" */ '../views/Count.vue')
  },
  {
    path: '/plomb-s',
    name: 'Korr',
    component: () => import(/* webpackChunkName: "plomb-s" */ '../views/Plomb-s.vue')
  },
  {
    path: '/plomb-r',
    name: 'Korr',
    component: () => import(/* webpackChunkName: "plomb-r" */ '../views/Plomb-r.vue')
  },
  {
    path: '/desc',
    name: 'Desc',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "desc" */ '../views/Desc.vue')
  },
  {
    path: '/grph',
    name: 'Grph',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "grph" */ '../views/Grph.vue')
  },
  {
    path: '/switch',
    name: 'Switch',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "switch" */ '../views/Switch.vue')
  },
  {
    path: '/heater',
    name: 'Heater',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "heater" */ '../views/Heater.vue')
  },
  {
    path: '/manometr',
    name: 'Manometr',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "manometr" */ '../views/Manometr.vue')
  },
  {
    path: '/filter',
    name: 'Filter',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "filter" */ '../views/Filter.vue')
  },
  {
    path: '/rd',
    name: 'Rd',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "rd" */ '../views/Rd.vue')
  },
  {
    path: '/rplomb',
    name: 'Rplomb',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "rplomb" */ '../views/Rplomb.vue')
  },
  {
    path: '/kplomb',
    name: 'Kplomb',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "kplomb" */ '../views/Kplomb.vue')
  },
  {
    path: '/splomb',
    name: 'Splomb',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "splomb" */ '../views/Splomb.vue')
  },
  {
    path: '/irvis',
    name: 'Irvis',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "irvis" */ '../views/Irvis.vue')
  },
  {
    path: '/bip',
    name: 'Bip',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "bip" */ '../views/Bip.vue')
  },
  {
    path: '/crane',
    name: 'Crane',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "crane" */ '../views/Crane.vue')
  },
  {
    path: '/cranelat',
    name: 'CraneLat',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "cranelat" */ '../views/CraneLat.vue')
  },
  {
    path: '/confuser',
    name: 'Confuser',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "confuser" */ '../views/Confuser.vue')
  },
  {
    path: '/diffuser',
    name: 'Diffuser',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "diffuser" */ '../views/Diffuser.vue')
  },
  {
    path: '/undergas',
    name: 'Undergas',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "undergas" */ '../views/Undergas.vue')
  },
  {
    path: '/boiler',
    name: 'Boiler',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "boiler" */ '../views/Boiler.vue')
  },
  {
    path: '/illegaltie',
    name: 'Illegaltie',
    meta: { desc: true },
    component: () => import(/* webpackChunkName: "illegaltie" */ '../views/Illegaltie.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
